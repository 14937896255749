<template>
  <div class="modal-authentication">
    <SfModal :visible="isVisible" @close="closeModal">
      <transition name="fade" mode="out-in">
        <Authentication
          :initial-mode="modalData.payload"
          @login-success="loginSuccess"
          @close="$emit('close', modalData.name)"
        />
      </transition>
    </SfModal>
  </div>
</template>

<script>
import { SfModal } from "@lib/components";
import Authentication from "@theme/components/molecules/authentication";
import { notifications } from "@lib/modules/cart/helpers";

export default {
  name: "ModalAuthentication",
  components: { SfModal, Authentication },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", this.modalData.name);
    },
    async loginSuccess(user) {
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "success",
          message: "Login Successful",
        })
      );
      this.$emit("close", this.modalData.name);
    },
  },
};
</script>

<style lang="scss">
.modal-authentication {
  .sf-modal__content {
    min-height: 360px;
  }
}
</style>
