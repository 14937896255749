<template>
  <div class="login-wrapper">
    <div style="text-align: center" v-if="loader">
      <div id="loading"></div>
    </div>
    <div v-else>
      <div class="text-center login-header">Login</div>
      <div class="alert full-width" v-if="showSignupAlert">
        <span>
          Please <a href="#" @click="signupMode">Sign Up</a> again if your
          account was created before 27th Oct 2020
        </span>
        <br />
        <br />
        <span v-if="!hasPhoneLogin"
          >Phone number login is discontinued. Please Whatsapp us at
          <a
            :href="
              'https://api.whatsapp.com/send?phone=+16506000086&text=Login%20via%20phone%20for%20store%20\'' +
              store.title +
              '\''
            "
            target="_blank"
          >
            +16506000086
          </a>
          to transfer an existing phone number account to email</span
        >
      </div>
      <div v-if="stage === 'details'" class="login-input-wrapper">
        <form>
          <SfInput
            v-if="hasPhoneLogin"
            type="text"
            name="phoneOrEmail"
            label="Phone number or email address "
            v-model.lazy="inputText"
            :valid="!$v.inputText.$error"
            :error-message="$t('Please enter a valid phone number or email.')"
            autocomplete="username"
            @blur="$v.inputText.$touch()"
            class="form__element phone-or-email"
          />
          <SfInput
            v-else
            type="text"
            name="emailAddress"
            label="Email address"
            v-model.lazy="inputText"
            :valid="!$v.inputText.$error"
            :error-message="$t('Please enter a valid email.')"
            autocomplete="username"
            @blur="$v.inputText.$touch()"
            class="form__element phone-or-email"
          />
          <SfInput
            v-model="password"
            name="password"
            label="Password"
            type="password"
            class="form__element"
            autocomplete="current-password"
            :valid="!$v.password.$error"
            :error-message="$t('Please enter your password.')"
            @blur="$v.password.$touch()"
            :has-show-password="true"
          />
          <div class="context-actions" style="text-align: right">
            <SfButton
              type="button"
              class="sf-button--text action-button"
              style="padding-right: 0; text-decoration: underline"
              @click="$emit('change-mode', 'forgot-password')"
            >
              Forgot password?
            </SfButton>
          </div>
          <div class="login-error" v-if="loginError">
            <span class="error-message">{{ loginError }}</span>
            <span class="error-help" v-if="loginNextAction">{{
              loginNextAction
            }}</span>
          </div>
          <div class="actions">
            <SfButton type="button" size="small" @click="tryLogin">
              Login
            </SfButton>
            <SfButton type="button" class="sf-button--text" @click="cancel"
              >Cancel</SfButton
            >
          </div>
        </form>
      </div>
      <div class="separator"></div>
      <div class="signup-container" v-if="showSignUpButton">
        <div class="text-center">
          <div style="font-size: 0.85rem">New to {{ store.title }}?</div>
          <a
            href="#"
            @click="signupMode"
            style="
              color: #214ed3;
              font-weight: 600;
              display: inline-block;
              padding-top: 0.75rem;
            "
            >Sign Up
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountry } from "@lib/utility/ipUtils";
import { SfButton, SfInput } from "@lib/components";
import { mapActions, mapGetters } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import config from "@config/config";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import telInput from "@theme/components/atoms/tel-input";

export default {
  components: {
    SfInput,
    SfButton,
  },
  props: {
    hasPhoneLogin: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      inputText: "",
      password: "",
      stage: "details",
      showSignUpButton: true,
      loader: false,
      loginError: null,
      loginNextAction: null,
      phoneNumber: null,
      otpSent: false,
      loginWaiting: false,
      enteredOtp: "",
    };
  },
  validations() {
    return {
      inputText: {
        required,
        complex(value) {
          if (this.inputType === "phone") {
            if (window.intlTelInputUtils) {
              return (
                window.intlTelInputUtils.isValidNumber(value) ||
                window.intlTelInputUtils.isValidNumber("+91" + value) ||
                window.intlTelInputUtils.isValidNumber("+" + value)
              );
            }
          } else {
            return /^[^@]+@[^@]+\.[^@]+$/.test(value);
          }
        },
      },
      password: {
        required,
        minLength: minLength(6),
      },
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
    showSignupAlert() {
      let isCreatedBefore28Oct = true;
      if (this.store.cAt) {
        isCreatedBefore28Oct =
          this.store.cAt._seconds < 1603909800 /* 29th OCT IST*/;
      }
      return this.showSignUpButton && isCreatedBefore28Oct;
    },
    inputType() {
      if (this.hasPhoneLogin) {
        if (/^[^@]+@[^@]+\.[^@]+$/.test(this.inputText)) return "email";
        if (/^[0-9()-]+$/.test(this.inputText)) return "phone";
        if (this.inputText.indexOf("@") !== -1) {
          return "email";
        }
        if (this.inputText.match(/^\d/) || this.inputText.startsWith("+")) {
          return "phone";
        } else {
          return "email";
        }
        return "email";
      } else {
        return "email";
      }
    },
    isOtpEntered() {
      return (
        this.enteredOtp &&
        this.enteredOtp.length === 6 &&
        /^\d*$/.test(this.enteredOtp)
      );
    },
  },
  methods: {
    ...mapActions({
      loginUsingEmail: "user/loginUsingEmail",
      loginUsingPhone: "user/loginUsingPhone",
    }),
    async tryLogin() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      this.showSignUpButton = false;
      this.loader = true;
      try {
        if (this.inputType === "phone") {
          this.phoneNumber = this.getFormattedNumber(this.inputText);
          await this.phoneLogin();
        } else if (this.inputType === "email") {
          await this.emailLogin();
        }
        this.loader = false;
      } catch (error) {
        let errorNotification = notifications.createNotification({
          type: "danger",
          message: `An error occurred while sending OTP. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        this.$emit("close");
        console.error(error);
      }
    },
    async phoneLogin() {
      const { success, errorCode, errorMessage } = await this.loginUsingPhone({
        phoneNumber: this.phoneNumber,
        password: this.password,
      });
      if (!success) {
        if (errorCode === "not-found") {
          this.loginError = "Couldn't find an account with this phone number.";

          this.loginNextAction = "Please sign up to create your account";
          this.showSignUpButton = true;
        } else {
          this.loginError = errorMessage;
        }
      } else {
        let successNotification = notifications.createNotification({
          type: "success",
          message: `Login Successful.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          successNotification
        );
        this.$emit("close");
      }
    },
    async emailLogin() {
      const { success, errorCode, errorMessage } = await this.loginUsingEmail({
        email: this.inputText.toLowerCase().trim(),
        password: this.password,
      });
      if (!success) {
        if (errorCode === "not-found") {
          this.loginError = "Couldn't find an account with this email address.";

          this.loginNextAction = "Please sign up to create your account";
          this.showSignUpButton = true;
        } else {
          this.loginError = errorMessage;
        }
      } else {
        let successNotification = notifications.createNotification({
          type: "success",
          message: `Login Successful.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          successNotification
        );
        this.$emit("close");
      }
    },
    cancel() {
      this.$emit("close");
    },
    signupMode() {
      this.$emit("change-mode", "signup");
    },
    getFormattedNumber(phoneText) {
      if (window.intlTelInputUtils) {
        if (window.intlTelInputUtils.isValidNumber(phoneText)) {
          return window.intlTelInputUtils.formatNumber(phoneText);
        } else if (window.intlTelInputUtils.isValidNumber("+91" + phoneText)) {
          return window.intlTelInputUtils.formatNumber("+91" + phoneText);
        }
      } else {
        if (phoneText.startsWith("+91")) {
          return phoneText;
        } else if (phoneText.startsWith("0")) {
          return "+91" + phoneText.substring(1);
        } else {
          return "+91" + phoneText;
        }
      }
      return phoneText;
    },
  },
  mounted() {
    document.querySelector(".phone-or-email input").focus();
    if (this.hasPhoneLogin && !window.intlTelInputUtils) {
      const phoneNumberUtils =
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js";
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = phoneNumberUtils;

      head.appendChild(script);
    }
  },
};
</script>

<style lang="scss">
.login-wrapper {
  .login-header {
    font-size: 1.4rem;
    text-align: center;
    padding: 0.5rem 0 2rem 0;
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    margin-left: -24px;
    margin-right: -24px;
    font-size: 0.9rem;
    line-height: 1.3rem;
    margin-bottom: 2rem;
  }
}

.login-input-wrapper {
  label {
    font-size: 0.95rem;
  }
  input {
    --input-border-color: var(--c-black);
    &:focus {
      outline: none;
    }
  }
}

.text-center {
  text-align: center;
}

.login-error {
  color: var(--c-danger);
  font-size: 0.95rem;
  padding: 0.5rem 0;

  .error-help {
    display: block;
    padding-top: 0.5rem;
  }
}
</style>
