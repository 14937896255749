<template>
  <div class="change-phone">
    <div style="text-align: center" v-if="loader">
      <div id="loading"></div>
    </div>
    <div v-else>
      <div class="text-center login-header">Update Phone Number</div>
      <div v-if="!otpSent" class="signup-input-wrapper">
        <SfInput
          type="text"
          label="Mobile number"
          name="phoneNumber"
          v-model.lazy="inputText"
          :valid="!$v.inputText.$error"
          :error-message="$t('Please enter a valid phone number.')"
          @blur="$v.inputText.$touch()"
          class="form__element phone-or-email"
        />
        <div class="login-error" v-if="loginError">
          <span class="error-message">{{ loginError }}</span>
          <span class="error-help" v-if="loginNextAction">{{
            loginNextAction
          }}</span>
        </div>
        <div class="actions">
          <SfButton
            size="small"
            @click="sendVerificationCode"
            :disabled="!isPhoneChanged || $v.inputText.$invalid"
          >
            Continue
          </SfButton>
          <SfButton class="sf-button--text" @click="cancel">Cancel</SfButton>
        </div>
      </div>
      <div v-if="otpSent" class="otp-container">
        <div>
          <label
            >Enter the 6 digit code we sent to
            <a class="phone-number-edit" href="#" @click="changeNumber">{{
              phoneNumber
            }}</a></label
          >
          <form method="get" data-autosubmit="false" autocomplete="off">
            <SfInput
              type="text"
              pattern="[0-9]*"
              v-model.lazy="enteredOtp"
              :valid="isOtpEntered"
              :error-message="$t('Please enter the 6 digit code.')"
              class="form__element"
            />
          </form>
          <div class="login-error" v-if="loginError">
            <span class="error-message">{{ loginError }}</span>
            <span class="error-help" v-if="loginNextAction">{{
              loginNextAction
            }}</span>
          </div>
          <div class="actions">
            <SfButton @click="update" :disabled="!isOtpEntered">
              Update
            </SfButton>
            <SfButton class="sf-button--pure" @click="cancel">CANCEL</SfButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountry } from "@lib/utility/ipUtils";
import { SfButton, SfInput } from "@lib/components";
import { mapActions, mapGetters } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import config from "@config/config";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import userService from "@lib/services/user";

export default {
  components: {
    SfInput,
    SfButton,
  },
  data() {
    return {
      loader: false,
      name: "",
      inputText: "",
      phoneNumber: null,
      numberCheckTimer: null,
      otpSent: false,
      resendTimerRemaining: null,
      resendCount: 0,
      loginError: null,
      loginNextAction: "",
      enteredOtp: "",
      password: "",
    };
  },
  validations() {
    return {
      inputText: {
        required,
        complex(value) {
          if (window.intlTelInputUtils) {
            return (
              window.intlTelInputUtils.isValidNumber(value) ||
              window.intlTelInputUtils.isValidNumber("+91" + value)
            );
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
      currentPhone: "user/phoneNumber",
    }),
    isPhoneChanged() {
      return this.currentPhone != this.inputText;
    },
    isOtpEntered() {
      return (
        this.enteredOtp &&
        this.enteredOtp.length === 6 &&
        /^\d*$/.test(this.enteredOtp)
      );
    },
  },
  methods: {
    ...mapActions({
      updatePhoneNumber: "user/updatePhoneNumber",
    }),
    async sendVerificationCode() {
      this.loginError = "";
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.loader = true;
      try {
        if (/^([0]|\+91)?[6-9]\d{9}$/.test(this.inputText) == false) {
          this.loginError =
            "We currently support Indian phone numbers (+91) only.";
          this.loader = false;
          return;
        }
        if (window.intlTelInputUtils) {
          if (window.intlTelInputUtils.isValidNumber(this.inputText)) {
            this.phoneNumber = window.intlTelInputUtils.formatNumber(
              this.inputText
            );
          } else if (
            window.intlTelInputUtils.isValidNumber("+91" + this.inputText)
          ) {
            this.phoneNumber = window.intlTelInputUtils.formatNumber(
              "+91" + this.inputText
            );
          }
        } else {
          if (this.inputText.startsWith("+91")) {
            this.phoneNumber = this.inputText;
          } else if (this.inputText.startsWith("0")) {
            this.phoneNumber = "+91" + this.inputText.substring(1);
          } else {
            this.phoneNumber = "+91" + this.inputText;
          }
        }
        const otpSendResult = await userService.sendOtpForVerification(
          this.phoneNumber
        );
        if (!otpSendResult.success) {
          if (otpSendResult.errorCode === "conflict") {
            this.loginError =
              "Account with this phone number already exists. Please use another number";
            this.showLoginButton = true;
          } else {
            this.loginError =
              otpSendResult.errorMessage ||
              "An error occurred while sending verification code to your phone number.";
          }
        } else {
          this.otpSent = true;
        }
        this.loader = false;
      } catch (error) {
        let errorNotification = notifications.createNotification({
          type: "danger",
          message: `An error occurred while sending verification code to your phone number. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        //this.$emit("close");
        this.loader = false;
        console.error(error);
      }
    },
    async update() {
      if (!this.isOtpEntered) return;
      const otp = this.enteredOtp;
      try {
        const {
          success,
          errorCode,
          errorMessage,
        } = await this.updatePhoneNumber({
          phoneNumber: this.phoneNumber,
          otp,
        });
        if (!success) {
          this.loginError = errorMessage;
          return;
        }
        let successNotification = notifications.createNotification({
          type: "success",
          message: `Phone number updated successfully.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          successNotification
        );
        this.$emit("close");
      } catch (error) {
        console.error(error);
        this.loginError = "An error occurred while connecting to the server";
      }
    },
    changeNumber() {
      this.$emit("is-active", false);
      this.otpSent = false;
      this.enteredOtp = "";
    },
    cancel() {
      this.$emit("close");
    },
  },
  mounted() {
    document.querySelector(".phone-or-email input").focus();
    if (!window.intlTelInputUtils) {
      const phoneNumberUtils =
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js";
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = phoneNumberUtils;

      head.appendChild(script);
    }
    this.inputText = this.currentPhone;
  },
  beforeDestroy() {
    if (this.numberCheckTimer) {
      clearInterval(this.numberCheckTimer);
    }
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers" as *;

.change-phone {
  .login-header {
    font-size: 1.4rem;
    text-align: center;
    padding: 0.5rem 0 2rem 0;
  }
}

.login-input-wrapper {
  label {
    font-size: 0.95rem;
  }
  input {
    --input-border-color: var(--c-black);
    &:focus {
      outline: none;
    }
  }
}

.text-center {
  text-align: center;
}

.login-error {
  color: var(--c-danger);
  font-size: 0.95rem;
  padding: 0.5rem 0;

  .error-help {
    display: block;
    padding-top: 0.5rem;
  }
}

.change-phone {
  padding: 30px 20px;

  .iti {
    margin: 30px 0 10px 0;
    width: 100%;
    border-radius: 0.25rem;

    input {
      width: 100%;
      letter-spacing: 0.1rem;
      font-size: 1.1rem;
      padding-left: 56px;
      padding-right: 12px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    input:focus {
      outline: none;
      border-bottom: 2px solid var(--c-primary);
    }

    .iti__selected-flag:focus {
      outline: none;
    }
  }

  .phone-validation-error {
    height: 1.2rem;
    text-align: right;
    color: var(--c-danger);
  }

  .actions .sf-button {
    float: right;
    margin-top: 10px;
  }

  .sf-button {
    --button-padding: var(--spacer-xs) var(--spacer-sm);
    --button-text-decoration: none;
    border-radius: 0.25rem;
    text-transform: none;

    &--pure {
      color: var(--c-primary);
    }
  }

  .otp-container {
    label {
      display: block;
      margin-bottom: 2rem;
      line-height: 2rem;
    }

    .sf-input {
      --input-border-color: var(--c-primary);
      --input-font-size: 1.5rem;

      input:focus,
      input:active {
        outline: none;
      }
    }

    .error {
      color: var(--c-danger);
      padding: 0 0 1rem 0;
    }
  }

  .phone-number-edit {
    color: #4285f4;
    font-variant: normal;
    font-weight: 400;
    font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }

  .actions {
    overflow: hidden;
    padding: 20px 0;

    .sf-button {
      --button-padding: var(--spacer-xs) var(--spacer-sm);
      border-radius: 0.25rem;
      --button-font-size: 1rem;

      &--text {
        color: var(--c-primary);
      }
    }
  }

  .resend-button-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#firebaseui-auth-container {
  @include for-desktop {
    margin: 48px 32px;
  }

  .firebaseui-card-content {
    @include for-mobile {
      padding: 0;
    }
  }
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin-top: 120px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
