<template>
  <div class="login-container">
    <loginViaEmailPhone
      v-if="mode === 'login'"
      :has-phone-login="hasPhoneLogin"
      @close="$emit('close')"
      @change-mode="mode = $event"
    />
    <signup
      v-if="mode === 'signup'"
      :has-phone-login="hasPhoneLogin"
      @close="$emit('close')"
      @change-mode="mode = $event"
    />
    <forgotPassword
      v-if="mode === 'forgot-password'"
      :has-phone-login="hasPhoneLogin"
      @close="$emit('close')"
      @change-mode="mode = $event"
    />
  </div>
</template>

<script>
import config from "@config/config";
import loginViaEmailPhone from "./login";
import signup from "./signup";
import forgotPassword from "./forgot-password";
import { mapGetters } from "vuex";

export default {
  components: {
    loginViaEmailPhone,
    signup,
    forgotPassword,
  },
  props: {
    initialMode: {
      type: String,
      default: "login",
    },
  },
  data() {
    return {
      mode: "login",
      active: false,
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
    hasPhoneLogin() {
      return this.store && this.store.countryCode === "IN";
    },
  },
  methods: {
    switchToSignup() {
      this.mode = "signup";
    },
    switchToEmail() {
      this.mode = "email";
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    this.mode = this.initialMode;
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers" as *;

.login-container {
  padding: 30px 20px;

  .iti {
    margin: 30px 0 10px 0;
    width: 100%;
    border-radius: 0.25rem;

    input {
      width: 100%;
      letter-spacing: 0.1rem;
      font-size: 1.1rem;
      padding-left: 56px;
      padding-right: 12px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    input:focus {
      outline: none;
      border-bottom: 2px solid var(--c-primary);
    }

    .iti__selected-flag:focus {
      outline: none;
    }
  }

  .phone-validation-error {
    height: 1.2rem;
    text-align: right;
    color: var(--c-danger);
  }

  .actions .sf-button {
    float: right;
    margin-top: 10px;
  }

  .sf-button {
    --button-padding: var(--spacer-xs) var(--spacer-sm);
    --button-text-decoration: none;
    border-radius: 0.25rem;
    text-transform: none;

    &--pure {
      color: var(--c-primary);
    }
  }

  .otp-container {
    label {
      display: block;
      margin-bottom: 2rem;
      line-height: 2rem;
    }

    .sf-input {
      --input-border-color: var(--c-primary);
      --input-font-size: 1.5rem;

      input:focus,
      input:active {
        outline: none;
      }
    }

    .error {
      color: var(--c-danger);
      padding: 0 0 1rem 0;
    }
  }

  .phone-number-edit {
    color: #4285f4;
    font-variant: normal;
    font-weight: 400;
    font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }

  .actions {
    overflow: hidden;
    padding: 20px 0;

    .sf-button {
      --button-padding: var(--spacer-xs) var(--spacer-sm);
      border-radius: 0.25rem;
      --button-font-size: 1rem;

      &--text {
        color: var(--c-primary);
      }
    }
  }

  .resend-button-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#firebaseui-auth-container {
  @include for-desktop {
    margin: 48px 32px;
  }

  .firebaseui-card-content {
    @include for-mobile {
      padding: 0;
    }
  }
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin-top: 120px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.digit-group {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 12%;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.1rem;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-family: "Raleway", sans-serif;
    font-weight: 200;
    margin: 0 2px;
  }

  .splitter {
    padding: 0 5px;
    font-size: 24px;
  }
}

// Firebase

.other-login {
  color: rgba(0, 0, 0, 0.87);
  font: 16px Roboto, arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  @include for-desktop {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .mdl-button {
    border: none;
    border-radius: 2px;
    color: #000;
    position: relative;
    min-width: 120px;
    height: 36px;
    margin: 0;
    padding: 0 12px;
    display: inline-block;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    margin: 10px 20px;

    @include for-desktop {
      margin: 0;
      min-width: 64px;
    }
  }

  .firebaseui-idp-button {
    direction: ltr;
    font-weight: 500;
    height: auto;
    line-height: normal;
    max-width: 220px;
    min-height: 40px;
    padding: 8px 12px;
    text-align: left;
  }

  .mdl-button--raised {
    background: rgba(158, 158, 158, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mdl-button--raised:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158, 158, 158, 0.4);
  }
  .mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158, 158, 158, 0.4);
  }

  .firebaseui-idp-google,
  .firebaseui-idp-google:hover,
  .mdl-button.firebaseui-idp-google:active,
  .mdl-button.firebaseui-idp-google:focus {
    background-color: #fff;
  }

  .firebaseui-idp-icon-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .firebaseui-idp-icon {
    border: none;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    width: 18px;
  }

  .firebaseui-idp-text {
    color: #fff;
    display: table-cell;
    font-size: 14px;
    padding-left: 12px;
    text-transform: none;
    vertical-align: middle;
  }

  .firebaseui-idp-google > .firebaseui-idp-text {
    color: #757575;
  }

  .firebaseui-idp-facebook,
  .firebaseui-idp-facebook:hover,
  .mdl-button.firebaseui-idp-facebook:active,
  .mdl-button.firebaseui-idp-facebook:focus {
    background-color: #3b5998;
  }

  .firebaseui-idp-email,
  .firebaseui-idp-email:hover,
  .mdl-button.firebaseui-idp-email:active,
  .mdl-button.firebaseui-idp-email:focus {
    background-color: #db4437;
  }

  .firebaseui-idp-phone,
  .firebaseui-idp-phone:hover,
  .mdl-button.firebaseui-idp-phone:active,
  .mdl-button.firebaseui-idp-phone:focus {
    background-color: #02bd7e;
  }
}
</style>
