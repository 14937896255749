<template>
  <div class="modal-phone-number-update">
    <SfModal :visible="isVisible" @close="closeModal">
      <transition name="fade" mode="out-in">
        <changePhoneNumber @close="$emit('close', modalData.name)" />
      </transition>
    </SfModal>
  </div>
</template>

<script>
import { notifications } from "@lib/modules/cart/helpers";

import { SfModal, SfInput, SfButton, SfAlert } from "@lib/components";
import PhoneInput from "@lib/components/atoms/PhoneInput.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import changePhoneNumber from "../change-phone-number";

export default {
  name: "ModalAuthentication",
  components: { SfModal, changePhoneNumber },
  data() {
    return {
      phone: {
        number: "",
        dialCode: "",
      },
      isPhoneValid: false,
      confirmationResult: null,
      confirmationCode: "",
      prevUser: null,
      errorMessage: "",
      resendTimerRemaining: null,
      resendButtonDisabled: false,
      resendCount: 0,
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      phoneNumber: "user/phoneNumber",
    }),
  },
  methods: {
    closeModal() {
      this.$emit("close", this.modalData.name);
    },
    async loginSuccess(user) {
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "success",
          message: "Login Successful",
        })
      );
      this.$emit("close", this.modalData.name);
    },
    async sendVerificationCode() {
      const phoneNumber = this.phone.dialCode + this.phone.number;
      const appVerifier = window.recaptchaVerifier;
      const self = this;
      self.resendTimerRemaining = 30;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          self.confirmationResult = confirmationResult;
          self.resendCount++;
          if (self.resendCount <= 3) {
            self.resendTimerRemaining = 30;
            var timer = setInterval(() => {
              self.resendTimerRemaining--;
              if (self.resendTimerRemaining === 0) {
                clearInterval(timer);
              }
            }, 1000);
          } else {
            self.resendButtonDisabled = true;
            self.errorMessage =
              "SMS verification could not be done. Please try again or contact your network operator";
          }
        })
        .catch((error) => {
          console.error(error);
          self.errorMessage =
            "An error occurred while sending OTP for verification. Please try again after some time";
          self.resendButtonDisabled = true;
        });
    },
    async confirmVerificationCode() {
      const code = this.confirmationCode;
      // try {
      //   var credential = firebase.auth.PhoneAuthProvider.credential(
      //     this.confirmationResult.verificationId,
      //     code
      //   );
      //   const user = firebase.auth().currentUser;
      //   await user.updatePhoneNumber(credential);
      //   await this.$store.dispatch("user/onLogin", user);
      //   this.$emit("close", this.modalData.name);
      // } catch (error) {
      //   if (error.code === "auth/credential-already-in-use") {
      //     this.errorMessage = "This number is already in use.";
      //   } else if (error.code === "auth/requires-recent-login") {
      //     this.errorMessage = "Your session has expired. Please login again.";
      //     this.showLoginLink = true;
      //   } else if (error.code === "auth/invalid-verification-code") {
      //     this.errorMessage =
      //       "Verification code is invalid. Please resend code and try again";
      //   }
      //   console.error(error);
      // }
      throw new Error("Removed");
    },
  },
  validations: {
    phone: {
      required,
      complex(value) {
        return this.isPhoneValid;
      },
    },
  },
  created() {
    this.prevUser = null;
    this.confirmationCode = "";
    this.confirmationResult = null;
    this.phone.number = this.phoneNumber;
    // this.prevUser = firebase.auth().currentUser;
    // firebase.auth().languageCode = "en";
  },
  mounted() {
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "recaptcha-container",
    //   {
    //     size: "invisible",
    //     callback: function (response) {
    //       onSignInSubmit();
    //     },
    //   }
    // );
    // recaptchaVerifier.render().then((widgetId) => {
    //   window.recaptchaWidgetId = widgetId;
    // });
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
#recaptcha-container {
  width: 100%;
  min-height: 60px;
  padding-bottom: 16px;

  .grecaptcha-badge {
    position: static !important;
    margin: 0 auto;
  }
}

.modal-phone-number-update {
  .sf-alert {
    padding: 16px;
  }

  .resend-timer {
    color: #888;
    font-size: var(--font-sm);
    text-align: right;

    .resend-verification-code {
      display: inline;
      --button-color: green;
      --button-font-size: var(--font-sm);
    }
  }
}
</style>
