<template>
  <div class="modal-add-email">
    <SfModal :visible="isVisible" @close="closeModal">
      <div>
        <transition name="fade" mode="out-in">
          <ChangeEmailAddress @close="$emit('close', modalData.name)" />
        </transition>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { notifications } from "@lib/modules/cart/helpers";
import { mapGetters } from "vuex";
import { SfModal } from "@lib/components";
import ChangeEmailAddress from "../change-email-address";

// Add a plain input, which calls an update email api, using the current auth token, and then updates the email address, and sends verification link.
export default {
  name: "ModalAuthentication",
  components: { SfModal, ChangeEmailAddress },
  data() {
    return {
      errorMessage: "",
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      email: "user/email",
    }),
  },
  watch: {
    email: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$emit("close", this.modalData.name);
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", this.modalData.name);
    },
    async loginSuccess(user) {
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "success",
          message: "Login Successful",
        })
      );
      this.$emit("close", this.modalData.name);
    },
  },
};
</script>

<style lang="scss">
.modal-add-email {
  padding: 20px 30px;
}

#recaptcha-container {
  width: 100%;
  min-height: 60px;
  padding-bottom: 16px;

  .grecaptcha-badge {
    position: static !important;
    margin: 0 auto;
  }
}
</style>
