<template>
  <div class="login-wrapper">
    <div style="text-align: center" v-if="loader">
      <div id="loading"></div>
    </div>
    <div v-else>
      <div class="text-center login-header">Forgot Password</div>
      <div v-if="stage === 'details'" class="login-input-wrapper">
        <form>
          <SfInput
            v-if="hasPhoneLogin"
            type="text"
            name="phoneOrEmail"
            label="Phone number or email address "
            v-model.lazy="inputText"
            :valid="!$v.inputText.$error"
            :error-message="$t('Please enter a valid phone number or email.')"
            autocomplete="username"
            @blur="$v.inputText.$touch()"
            class="form__element phone-or-email"
          />
          <SfInput
            v-else
            type="text"
            name="emailAddress"
            label="Email address "
            v-model.lazy="inputText"
            :valid="!$v.inputText.$error"
            :error-message="$t('Please enter a valid email address.')"
            autocomplete="username"
            @blur="$v.inputText.$touch()"
            class="form__element phone-or-email"
          />
          <div class="login-error" v-if="errorMessage">
            <span class="error-message">{{ errorMessage }}</span>
          </div>
          <div class="actions">
            <SfButton type="button" size="small" @click="sendVerificationCode">
              Continue
            </SfButton>
            <SfButton type="button" class="sf-button--text" @click="cancel"
              >Cancel</SfButton
            >
          </div>
        </form>
      </div>
      <div v-if="stage === 'verificationCodeSent'" class="otp-container">
        <div>
          <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
          <label>
            Enter the 6 digit code we sent to <b>{{ formattedInput }}</b>
          </label>
          <form method="get" data-autosubmit="false" autocomplete="off">
            <SfInput
              type="text"
              pattern="[0-9]*"
              v-model.lazy="enteredOtp"
              :valid="!$v.enteredOtp.$error"
              :error-message="
                !$v.enteredOtp.required
                  ? $t('Please enter the 6 digit code.')
                  : $t('Please enter a valid code.')
              "
              class="form__element"
            />
          </form>
          <div class="actions">
            <SfButton @click="verifyOtpAndContinue" :disabled="!isOtpEntered"
              >CONTINUE</SfButton
            >
            <SfButton class="sf-button--pure" @click="cancel">CANCEL</SfButton>
          </div>
        </div>
      </div>
      <div
        v-if="stage === 'verificationCodeSent'"
        class="resend-button-container"
      >
        <span v-if="resendTimerRemaining">
          Resend code in 0:<span v-if="resendTimerRemaining < 10">0</span
          >{{ resendTimerRemaining }}
        </span>
        <SfButton
          v-if="resendTimerRemaining === 0 && resendCount < 3"
          class="sf-button--pure resend-verification-code"
          @click="sendOtp"
        >
          Resend Code
        </SfButton>
      </div>
      <div v-if="stage === 'new-password'" class="login-input-wrapper">
        <form>
          <SfInput
            v-model="password"
            name="password"
            label="Enter new password"
            type="password"
            class="form__element"
            autocomplete="new-password"
            :valid="!$v.password.$error"
            :error-message="$t('Please enter your password.')"
            @blur="$v.password.$touch()"
            :has-show-password="true"
          />
          <div class="login-error" v-if="errorMessage">
            <span class="error-message">{{ errorMessage }}</span>
          </div>
          <div class="actions">
            <SfButton type="button" size="small" @click="resetPassword">
              Finish
            </SfButton>
            <SfButton type="button" class="sf-button--text" @click="cancel"
              >Cancel</SfButton
            >
          </div>
        </form>
      </div>
      <div class="separator" v-if="stage === 'details'"></div>
      <div class="login-container" v-if="stage === 'details'">
        <a
          href="#"
          @click="$emit('change-mode', 'login')"
          style="color: #214ed3; font-weight: 600"
          >Back to login
          <font-awesome-icon style="margin-left: 3px" icon="caret-right" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountry } from "@lib/utility/ipUtils";
import { SfButton, SfInput } from "@lib/components";
import { mapActions, mapGetters } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import config from "@config/config";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import telInput from "@theme/components/atoms/tel-input";

export default {
  components: {
    SfInput,
    SfButton,
  },
  data() {
    return {
      inputText: "",
      formattedInput: "",
      password: "",
      stage: "details",
      showSignUpButton: true,
      loader: false,
      errorMessage: null,
      isInputValid: false,
      countryCode: null,
      phoneValidationError: null,
      phoneNumber: null,
      numberCheckTimer: null,
      otpSent: false,
      resendTimerRemaining: null,
      resendCount: 0,
      loginWaiting: false,
      enteredOtp: "",
      emailAddress: null,
      emailValidationError: null,
    };
  },
  props: {
    hasPhoneLogin: {
      required: true,
      type: Boolean,
    },
  },
  validations() {
    return {
      inputText: {
        required,
        complex(value) {
          if (this.inputType === "phone") {
            if (window.intlTelInputUtils) {
              return (
                window.intlTelInputUtils.isValidNumber(value) ||
                window.intlTelInputUtils.isValidNumber("+91" + value) ||
                window.intlTelInputUtils.isValidNumber("+" + value)
              );
            }
          } else {
            return /^[^@]+@[^@]+\.[^@]+$/.test(value);
          }
        },
      },
      password: {
        required,
        minLength: minLength(6),
      },
      enteredOtp: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
        numeric,
      },
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
    inputType() {
      if (this.hasPhoneLogin) {
        if (/^[^@]+@[^@]+\.[^@]+$/.test(this.inputText)) return "email";
        if (/^[0-9()-]+$/.test(this.inputText)) return "phone";
        if (this.inputText.indexOf("@") !== -1) {
          return "email";
        }
        if (this.inputText.match(/^\d/) || this.inputText.startsWith("+")) {
          return "phone";
        } else {
          return "email";
        }
        return "email";
      } else {
        return "email";
      }
    },
    isOtpEntered() {
      return (
        this.enteredOtp &&
        this.enteredOtp.length === 6 &&
        /^\d*$/.test(this.enteredOtp)
      );
    },
  },
  methods: {
    ...mapActions({
      sendResetCodeToPhone: "user/sendPasswordResetCodeToPhone",
      sendResetCodeToEmail: "user/sendPasswordResetCodeToEmail",
      verifyResetCodeForPhone: "user/verifyPasswordResetCodeForPhone",
      verifyResetCodeForEmail: "user/verifyPasswordResetCodeForEmail",
      resetPhonePassword: "user/resetPhonePassword",
      resetEmailPassword: "user/resetEmailPassword",
      loginUsingOtp: "user/loginUsingPhoneOtp",
      loginUsingEmail: "user/loginUsingEmail",
      loginUsingPhone: "user/loginUsingPhone",
    }),
    async sendVerificationCode() {
      this.errorMessage = "";
      if (this.$v.inputText.$invalid) {
        this.$v.inputText.$touch();
        return;
      }

      this.loader = true;
      try {
        if (this.inputType === "phone") {
          this.formattedInput = this.getFormattedNumber(this.inputText);
          await this.sendResetCodeToPhone(this.formattedInput);
        } else if (this.inputType === "email") {
          this.formattedInput = this.inputText;
          await this.sendResetCodeToEmail(this.inputText);
        }
        this.stage = "verificationCodeSent";
        this.loader = false;
      } catch (error) {
        this.loader = false;
        let errorNotification = notifications.createNotification({
          type: "danger",
          message:
            error.message ||
            `An error occurred while sending OTP. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        this.$emit("close");
        console.error(error);
      }
    },
    async resetPassword() {
      this.errorMessage = "";
      if (
        this.$v.inputText.$invalid ||
        this.$v.enteredOtp.$invalid ||
        this.$v.password.$invalid
      ) {
        this.$v.password.$touch();
        return;
      }

      this.loader = true;
      try {
        if (this.inputType === "phone") {
          const otp = this.enteredOtp;
          const phoneNumber = this.formattedInput;
          const password = this.password;
          const { success, errorMessage } = await this.resetPhonePassword({
            phoneNumber,
            password,
            otp,
          });
          if (success) {
            let successNotification = notifications.createNotification({
              type: "success",
              message: "Your Password was reset succesfully.",
            });
            this.$store.dispatch(
              "notification/spawnNotification",
              successNotification
            );
            this.$emit("close");
          } else {
            this.errorMessage = errorMessage;
          }
        } else if (this.inputType === "email") {
          const otp = this.enteredOtp;
          const email = this.inputText.toLowerCase().trim();
          const password = this.password;
          const { success, errorMessage } = await this.resetEmailPassword({
            email,
            password,
            otp,
          });
          if (success) {
            let successNotification = notifications.createNotification({
              type: "success",
              message: "Your Password was reset succesfully.",
            });
            this.$store.dispatch(
              "notification/spawnNotification",
              successNotification
            );
            this.$emit("close");
          } else {
            this.errorMessage = errorMessage;
          }
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        let errorNotification = notifications.createNotification({
          type: "danger",
          message:
            error.message ||
            `An error occurred while sending OTP. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        this.$emit("close");
        console.error(error);
      }
    },
    async verifyOtpAndContinue() {
      this.errorMessage = "";
      if (this.$v.enteredOtp.$invalid) {
        this.$v.enteredOtp.$touch();
        return;
      }
      const otp = this.enteredOtp;
      this.loader = true;
      try {
        if (this.inputType === "phone") {
          const {
            success,
            errorCode,
            errorMessage,
          } = await this.verifyResetCodeForPhone({
            phoneNumber: this.formattedInput,
            otp,
          });

          if (success) {
            this.stage = "new-password";
          } else {
            this.errorMessage = errorMessage;
          }
        } else if (this.inputType === "email") {
          const {
            success,
            errorCode,
            errorMessage,
          } = await this.verifyResetCodeForEmail({
            email: this.formattedInput.toLowerCase().trim(),
            otp,
          });

          if (success) {
            this.stage = "new-password";
          } else {
            this.errorMessage = errorMessage;
          }
        }
        this.loader = false;
      } catch (error) {
        console.error(error);
        this.errorMessage = error.message;
        this.loader = false;
      }
    },
    cancel() {
      this.$emit("close");
    },
    signupMode() {
      this.$emit("change-mode", "signup");
    },
    getFormattedNumber(phoneText) {
      if (window.intlTelInputUtils) {
        if (window.intlTelInputUtils.isValidNumber(phoneText)) {
          return window.intlTelInputUtils.formatNumber(phoneText);
        } else if (window.intlTelInputUtils.isValidNumber("+91" + phoneText)) {
          return window.intlTelInputUtils.formatNumber("+91" + phoneText);
        }
      } else {
        if (phoneText.startsWith("+91")) {
          return phoneText;
        } else if (phoneText.startsWith("0")) {
          return "+91" + phoneText.substring(1);
        } else {
          return "+91" + phoneText;
        }
      }
      return phoneText;
    },
  },
  mounted() {
    document.querySelector(".phone-or-email input").focus();
    const phoneNumberUtils =
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js";
    if (!window.intlTelInputUtils) {
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = phoneNumberUtils;

      head.appendChild(script);
    }
  },
  beforeDestroy() {
    if (this.numberCheckTimer) {
      clearInterval(this.numberCheckTimer);
    }
  },
};
</script>
