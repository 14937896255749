<template>
  <div class="login-wrapper">
    <div style="text-align: center" v-if="loader">
      <div id="loading"></div>
    </div>
    <div v-else>
      <div class="text-center login-header">Create Account</div>
      <div v-if="!otpSent" class="signup-input-wrapper">
        <SfInput
          type="text"
          label="Your name"
          name="fullName"
          v-model.lazy="name"
          :valid="!$v.name.$error"
          :error-message="$t('Please enter a your name.')"
          @blur="$v.name.$touch()"
          class="form__element full-name"
        />
        <SfInput
          v-if="hasPhoneLogin"
          type="text"
          name="phoneOrEmail"
          label="Mobile number or email address"
          v-model.lazy="inputText"
          :valid="!$v.inputText.$error"
          :error-message="$t('Please enter a valid phone number or email.')"
          @blur="$v.inputText.$touch()"
          class="form__element phone-or-email"
        />
        <SfInput
          v-else
          type="text"
          name="emailAddress"
          label="Email address"
          v-model.lazy="inputText"
          :valid="!$v.inputText.$error"
          :error-message="$t('Please enter a valid email address.')"
          @blur="$v.inputText.$touch()"
          class="form__element phone-or-email"
        />
        <SfInput
          v-model="password"
          name="password"
          label="Password"
          type="password"
          class="form__element"
          :valid="!$v.password.$error"
          :error-message="$t('Please enter your password.')"
          @blur="$v.password.$touch()"
          :has-show-password="true"
        />
        <div class="login-error" v-if="loginError">
          <span class="error-message">{{ loginError }}</span>
          <span class="error-help" v-if="loginNextAction">{{
            loginNextAction
          }}</span>
        </div>
        <div class="actions">
          <SfButton size="small" @click="signupSubmit"> Sign Up </SfButton>
          <SfButton class="sf-button--text" @click="cancel">Cancel</SfButton>
        </div>
      </div>
      <div v-if="otpSent" class="otp-container">
        <div>
          <label
            >Enter the 6 digit code we sent to
            <a class="phone-number-edit" href="#" @click="changeNumber">{{
              phoneNumber
            }}</a></label
          >
          <form method="get" data-autosubmit="false" autocomplete="off">
            <SfInput
              type="text"
              pattern="[0-9]*"
              v-model.lazy="enteredOtp"
              :valid="isOtpEntered"
              :error-message="$t('Please enter the 6 digit code.')"
              class="form__element"
            />
          </form>
          <div class="login-error" v-if="loginError">
            <span class="error-message">{{ loginError }}</span>
            <span class="error-help" v-if="loginNextAction">{{
              loginNextAction
            }}</span>
          </div>
          <div class="actions">
            <SfButton @click="submitOtp" :disabled="!isOtpEntered"
              >CONTINUE</SfButton
            >
            <SfButton class="sf-button--pure" @click="cancel">CANCEL</SfButton>
          </div>
        </div>
      </div>
      <div class="resend-button-container">
        <span v-if="resendTimerRemaining">
          Resend code in 0:<span v-if="resendTimerRemaining < 10">0</span
          >{{ resendTimerRemaining }}
        </span>
        <SfButton
          v-if="resendTimerRemaining === 0 && resendCount < 3"
          class="sf-button--pure resend-verification-code"
          @click="sendOtp"
        >
          Resend
        </SfButton>
      </div>
      <div class="separator" v-if="showLoginButton"></div>
      <div class="login-container" v-if="showLoginButton">
        Already have an account?
        <a href="#" @click="loginMode" style="color: #214ed3; font-weight: 600"
          >Sign in
          <font-awesome-icon style="margin-left: 3px" icon="caret-right" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountry } from "@lib/utility/ipUtils";
import { SfButton, SfInput } from "@lib/components";
import { mapActions } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import config from "@config/config";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import userService from "@lib/services/user";

export default {
  components: {
    SfInput,
    SfButton,
  },
  props: {
    hasPhoneLogin: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      loader: false,
      name: "",
      inputText: "",
      phoneNumber: null,
      numberCheckTimer: null,
      otpSent: false,
      resendTimerRemaining: null,
      resendCount: 0,
      loginError: null,
      loginNextAction: "",
      enteredOtp: "",
      password: "",
      showLoginButton: true,
    };
  },
  validations() {
    return {
      name: {
        required,
        minLength: minLength(2),
      },
      inputText: {
        required,
        complex(value) {
          if (this.inputType === "phone") {
            if (window.intlTelInputUtils) {
              return (
                window.intlTelInputUtils.isValidNumber(value) ||
                window.intlTelInputUtils.isValidNumber("+91" + value)
              );
            }
          } else {
            return /^[^@]+@[^@]+\.[^@]+$/.test(value);
          }
        },
      },
      password: {
        required,
        minLength: minLength(6),
      },
    };
  },
  computed: {
    inputType() {
      if (this.hasPhoneLogin) {
        if (/^[^@]+@[^@]+\.[^@]+$/.test(this.inputText)) return "email";
        if (/^[0-9()-]+$/.test(this.inputText)) return "phone";
        if (this.inputText.indexOf("@") !== -1) {
          return "email";
        }
        if (this.inputText.match(/^\d/) || this.inputText.startsWith("+")) {
          return "phone";
        } else {
          return "email";
        }
        return "email";
      } else {
        return "email";
      }
    },
    isOtpEntered() {
      return (
        this.enteredOtp &&
        this.enteredOtp.length === 6 &&
        /^\d*$/.test(this.enteredOtp)
      );
    },
  },
  methods: {
    ...mapActions({
      signupUsingPhone: "user/signupUsingPhone",
      signupUsingEmail: "user/signupUsingEmail",
    }),
    async signupSubmit() {
      this.loginError = "";
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.showLoginButton = false;
      this.loader = true;
      try {
        if (this.inputType === "phone") {
          await this.phoneSignup();
        } else if (this.inputType === "email") {
          await this.emailSignup();
        }
        this.loader = false;
      } catch (error) {
        let errorNotification = notifications.createNotification({
          type: "danger",
          message: `An error occurred while signing you up. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        //this.$emit("close");
        console.error(error);
      }
    },
    async phoneSignup() {
      if (/^([0]|\+91)?[6-9]\d{9}$/.test(this.inputText) == false) {
        this.loginError =
          "We currently support Indian phone numbers (+91) only. Please use your email to signup otherwise.";
        return;
      }
      if (window.intlTelInputUtils) {
        if (window.intlTelInputUtils.isValidNumber(this.inputText)) {
          this.phoneNumber = window.intlTelInputUtils.formatNumber(
            this.inputText
          );
        } else if (
          window.intlTelInputUtils.isValidNumber("+91" + this.inputText)
        ) {
          this.phoneNumber = window.intlTelInputUtils.formatNumber(
            "+91" + this.inputText
          );
        }
      } else {
        if (this.inputText.startsWith("+91")) {
          this.phoneNumber = this.inputText;
        } else if (this.inputText.startsWith("0")) {
          this.phoneNumber = "+91" + this.inputText.substring(1);
        } else {
          this.phoneNumber = "+91" + this.inputText;
        }
      }
      const otpSendResult = await userService.sendOtpForPhoneSignup(
        this.name,
        this.phoneNumber
      );
      if (!otpSendResult.success) {
        if (otpSendResult.errorCode === "conflict") {
          this.loginError =
            "Account already exists. Please Sign in using the button below";
          this.showLoginButton = true;
        } else {
          this.loginError =
            otpSendResult.errorMessage ||
            "An error occurred while sending verification code to your phone number.";
        }
        return;
      }
      this.otpSent = true;
    },
    async emailSignup() {
      try {
        const {
          success,
          errorCode,
          errorMessage,
        } = await this.signupUsingEmail({
          name: this.name,
          email: this.inputText.toLowerCase().trim(),
          password: this.password,
        });
        if (!success) {
          this.loginError = errorMessage;
          return;
        }
        let successNotification = notifications.createNotification({
          type: "success",
          message: `Login Successful.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          successNotification
        );
        this.$emit("close");
      } catch (error) {
        console.error(error);
        this.loginError = "An error occurred while connecting to the server";
      }
    },
    changeNumber() {
      this.$emit("is-active", false);
      this.otpSent = false;
      this.enteredOtp = "";
    },
    async submitOtp() {
      if (!this.isOtpEntered) return;
      const otp = this.enteredOtp;
      try {
        const {
          success,
          errorCode,
          errorMessage,
        } = await this.signupUsingPhone({
          name: this.name,
          phoneNumber: this.phoneNumber,
          password: this.password,
          otp,
        });
        if (!success) {
          this.loginError = errorMessage;
          return;
        }
        let successNotification = notifications.createNotification({
          type: "success",
          message: `Login Successful.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          successNotification
        );
        this.$emit("close");
      } catch (error) {
        console.error(error);
        this.loginError = "An error occurred while connecting to the server";
      }
    },
    cancel() {
      this.$emit("close");
    },
    loginMode() {
      this.$emit("change-mode", "login");
    },
  },
  mounted() {
    document.querySelector(".full-name input").focus();
    if (this.hasPhoneLogin && !window.intlTelInputUtils) {
      const phoneNumberUtils =
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js";
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = phoneNumberUtils;

      head.appendChild(script);
    }
  },
  beforeDestroy() {
    if (this.numberCheckTimer) {
      clearInterval(this.numberCheckTimer);
    }
  },
};
</script>

<style lang="scss">
.login-wrapper {
  .login-header {
    font-size: 1.4rem;
    text-align: center;
    padding: 0.5rem 0 2rem 0;
  }

  .separator {
    height: 1px;
    background: rgba(0, 0, 0, 0.15);
    margin: 1rem 0 1.5rem 0;
  }
}

.signup-input-wrapper {
  label {
    font-size: 0.95rem;
  }
  input {
    --input-border-color: rgba(0, 0, 0, 0.15);

    &:focus {
      outline: none;
    }
  }
}
</style>
