<template>
  <div class="modal-phone-number-update">
    <SfModal :visible="isVisible" @close="closeModal">
      <transition name="fade" mode="out-in">
        <ChangeFullName @close="closeModal" />
      </transition>
    </SfModal>
  </div>
</template>

<script>
import { SfModal } from "@lib/components";
import ChangeFullName from "@theme/components/molecules/change-full-name";

export default {
  components: { SfModal, ChangeFullName },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", this.modalData.name);
    },
  },
};
</script>
