<template>
  <div class="modal-order-cancel">
    <SfModal :visible="isVisible" @close="closeModal">
      <transition name="fade" mode="out-in">
        <div class="card" v-if="order != null">
          <div class="title">Order help: #{{ order.bill.toUpperCase() }}</div>
          <div class="content">
            <div class="help-row" v-if="store.contact.whatsappNumber">
              Chat with us on
              <SfButton class="chat-whatsapp" @click.native="whatsappHelp">
                <font-awesome-icon
                  :icon="['fab', 'whatsapp']"
                  size="lg"
                  class="pr-2"
                  style="margin-right: 0.5rem"
                />
                {{ $t("Whatsapp") }}
              </SfButton>
            </div>
            <div
              class="help-row"
              v-if="store.contact.whatsappNumber && store.contact.email"
            >
              OR
            </div>
            <div class="help-row" v-if="store.contact.email">
              Send us an
              <a :href="emailLink()" target="_blank">
                <SfButton class="send-email">
                  <font-awesome-icon
                    :icon="['far', 'envelope']"
                    size="lg"
                    class="pr-2"
                    style="margin-right: 0.5rem"
                  />
                  {{ $t("Email") }}
                </SfButton>
              </a>
            </div>
            <div>
              Or you can
              <span v-if="store.contact.email">
                email us directly on
                <b>
                  <a :href="'mailto:' + store.contact.email">
                    {{ store.contact.email }}
                  </a>
                </b>
                or
              </span>
              <span v-if="store.contact.whatsappNumber">
                Whatsapp us at:
                <b>
                  <a
                    :href="
                      'https://api.whatsapp.com/send?phone=' +
                      store.contact.whatsappNumber
                    "
                  >
                    {{ this.store.contact.whatsappNumber }}
                  </a>
                </b>
              </span>
            </div>
          </div>
          <div class="actions">
            <SfButton class="sf-button--text close-btn" @click="closeModal">
              Cancel
            </SfButton>
          </div>
        </div>
      </transition>
    </SfModal>
  </div>
</template>

<script>
import { SfModal, SfButton } from "@lib/components";
import { notifications } from "@lib/modules/cart/helpers";
import { mapGetters } from "vuex";

export default {
  name: "ModalOrderCancel",
  components: { SfModal, SfButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
    order() {
      if (this.modalData.payload && this.modalData.payload.order) {
        return this.modalData.payload.order;
      }
      return null;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", this.modalData.name);
    },
    whatsappHelp() {
      let messages = [
        `Help needed regarding order #${this.order.bill.toUpperCase()}`,
      ];

      let messageString = `${encodeURIComponent(messages.join("\n"))}`;
      window.open(
        `https://api.whatsapp.com/send?phone=${this.store.contact.whatsappNumber}&text=${messageString}`,
        "_blank"
      );
    },
    emailHelp() {
      let subject = `Help needed regarding order #${this.order.bill.toUpperCase()} by  ${
        this.order.order.personalDetails.fullName
      }`;
      let mailLink = encodeURI(
        `mailto:${this.store.contact.email}?subject=${subject}&body= `
      );
      window.open(mailLink, "_blank");
    },
    emailLink() {
      let subject = `Help needed regarding order #${this.order.bill.toUpperCase()} by  ${
        this.order.order.personalDetails.fullName
      }`;
      let mailLink = encodeURI(
        `mailto:${this.store.contact.email}?subject=${subject}&body= `
      );
      return mailLink;
    },
    async confirmCancel(orderId) {
      this.$emit("close", this.modalData.name);
    },
  },
};
</script>

<style lang="scss" scoped>
$base-blue: #3276e3;
$medium-blue: darken($base-blue, 15%);
$dark-blue: darken($base-blue, 20%);
$light-blue: lighten($base-blue, 20%);
$soft-blue: lighten($base-blue, 10%);

.help-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  .sf-button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    margin-left: 1rem;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.card {
  text-align: center;
  border-radius: 8px;
  margin: 30px 0;
}

.title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 40px;
}

.sub-title {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}

.content {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;
  text-align: left;
}

.actions {
  display: flex;
  justify-content: flex-end;

  .sf-button {
    --button-padding: var(--spacer-xs) var(--spacer-sm);
    border-radius: 0.25rem;
    --button-font-size: 1rem;

    &--text {
      color: var(--c-primary);
    }
  }
}

.actions a {
  box-sizing: border-box;
  padding: 0.66rem 1.33rem;
  margin: 0px 8px;
  line-height: 16px;
  background: $medium-blue;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: white;
  text-decoration: none;
  border-radius: 8px;
}

.actions .nah {
  color: $dark-blue;
  background: $light-blue;
}

.actions .nah:hover {
  background: $soft-blue;
}

.actions a:hover {
  background: $dark-blue;
}
</style>
