<template>
  <div>
    <input
      :class="{ error: !!phoneValidationError }"
      type="tel"
      @blur="onBlur"
      @input="onInput"
      ref="phone"
    />
  </div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import { getCountry } from "@lib/utility/ipUtils";

export default {
  props: {
    value: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      telInstance: null,
      phoneValidationError: null,
      isValidPhoneNumber: false,
    };
  },
  methods: {
    onBlur(e) {
      console.log("onBlur");
      if (this.$refs.phone.value.trim()) {
        if (!this.telInstance.isValidNumber()) {
          this.$emit(
            "error",
            errorMap[self.telInstance.getValidationError()] || "Invalid number"
          );
        }
      }
    },
    onInput(e) {
      console.log("onInput");
      this.$emit("input", this.telInstance.getNumber());
      if (!this.telInstance) return;
      if (!this.telInstance.isValidNumber()) {
        this.$emit("input", null);
      } else {
        this.$emit("input", this.telInstance.getNumber());
      }
    },
  },
  mounted() {
    const input = this.$refs.phone;

    const phoneNumberUtils =
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js";

    const self = this;
    const iti = intlTelInput(input, {
      initialCountry: "auto",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.5/js/utils.min.js",
      geoIpLookup: async function (success, failure) {
        try {
          self.countryCode = self.countryCode || (await getCountry());
          success(self.countryCode);
        } catch (error) {
          failure(error);
        }
      },
    });

    iti.setNumber(this.value);

    this.telInstance = iti;
    // var errorMap = [
    //   "Invalid number",
    //   "Invalid country code",
    //   "Too short",
    //   "Too long",
    //   "Invalid number",
    // ];

    // // Check every 500 msec if
    // self.numberCheckTimer = setInterval(() => {
    //   if (!self.telInstance) return;
    //   if (!self.telInstance.isValidNumber()) {
    //     self.$emit("input", null);
    //   } else {
    //     self.$emit("input", self.telInstance.getNumber());
    //   }
    // }, 500);
  },
  beforeDestroy() {
    if (this.telInstance) {
      this.telInstance.destroy();
      this.telInstance = null;
    }
  },
};
</script>

<style scoped>
.iti__flag {
  background-image: url("~intl-tel-input/build/img/flags.png");
}

.iti__country-list {
  max-width: 380px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("~intl-tel-input/build/img/flags@2x.png");
  }
}
</style>
