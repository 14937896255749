<template>
  <div class="transition-container">
    <div style="text-align: center" v-if="loader">
      <div id="loading"></div>
    </div>
    <div v-else>
      <SfAlert v-if="errorMessage" :message="errorMessage" type="danger" />
      <div class="text-center heading">Change Name</div>
      <div class="fullname-input-wrapper">
        <SfInput
          v-model.trim="fullName"
          :value="fullName"
          label="Full name"
          name="fullName"
          class="form__element form__element--half"
          :required="true"
          :valid="!$v.fullName.$error"
          :error-message="
            !$v.fullName.required
              ? $t('Field is required')
              : $t('Name must have at least 2 letters.')
          "
          @blur="$v.fullName.$touch()"
        />
        <div class="actions">
          <SfButton @click="update" :disabled="!isNameChanged">Update</SfButton>
          <SfButton class="sf-button--pure" @click="cancel">CANCEL</SfButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notifications } from "@lib/modules/cart/helpers";

import { SfInput, SfButton, SfAlert } from "@lib/components";

import PhoneInput from "@lib/components/atoms/PhoneInput.vue";

import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ModalAuthentication",
  components: { SfButton, SfInput, SfAlert },
  data() {
    return {
      fullName: "",
      errorMessage: "",
      loader: false,
    };
  },
  computed: {
    ...mapGetters({
      storedName: "user/displayName",
    }),
    isNameChanged() {
      return this.storedName != this.fullName;
    },
  },
  validations() {
    return {
      fullName: {
        required,
        minLength: minLength(2),
      },
    };
  },
  methods: {
    ...mapActions({
      updateFullName: "user/updateName",
    }),
    async update() {
      this.errorMessage = "";
      if (this.$v.fullName.$invalid) {
        this.$v.fullName.$touch();
        return;
      }

      this.loader = true;
      try {
        const { success, errorMessage } = await this.updateFullName({
          fullName: this.fullName,
        });
        if (!success) {
          this.errorMessage = errorMessage;
        } else {
          this.$emit("close");
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        let errorNotification = notifications.createNotification({
          type: "danger",
          message:
            error.message ||
            `An error occurred while updating your name. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        this.$emit("close");
        console.error(error);
      }
    },
    cancel() {
      this.$emit("close");
    },
  },
  created() {
    this.fullName = this.storedName;
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 1.4rem;
  text-align: center;
  padding: 0.5rem 0 2rem 0;
}

label {
  font-size: 0.95rem;
}
input {
  --input-border-color: var(--c-black);
  &:focus {
    outline: none;
  }
}

.actions {
  overflow: hidden;
  padding: 20px 0;

  .sf-button {
    float: right;
    margin-top: 10px;
    --button-padding: var(--spacer-xs) var(--spacer-sm);
    border-radius: 0.25rem;
    --button-font-size: 1rem;
    --button-text-decoration: none;
    text-transform: none;

    &--pure {
      color: var(--c-primary);
    }

    &--text {
      color: var(--c-primary);
    }
  }
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin-top: 120px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
