<template>
  <div class="modal-order-cancel">
    <SfModal :visible="isVisible" @close="closeModal">
      <transition name="fade" mode="out-in">
        <div
          class="card"
          v-if="
            modalData.payload &&
            modalData.payload.bill &&
            modalData.payload.orderId
          "
        >
          <div class="alert">
            Cancellation is not guaranteed, but we will do our best to try
            cancelling the order.
          </div>
          <div class="title">Confirm Request</div>
          <div class="sub-title">
            Request cancellation: Order #{{
              modalData.payload.bill.toUpperCase()
            }}
          </div>
          <div class="content">
            If your order can be cancelled, we will send you a notification
            shortly.
          </div>
          <div class="actions">
            <a class="nah" @click="closeModal" href="#">Close</a>
            <a href="#" @click="confirmCancel">Confirm</a>
          </div>
        </div>
      </transition>
    </SfModal>
  </div>
</template>

<script>
import { SfModal } from "@lib/components";
import { notifications } from "@lib/modules/cart/helpers";

export default {
  name: "ModalOrderCancel",
  components: { SfModal },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    orderId() {
      if (this.modalData && this.modalData.payload) {
        return this.modalData.payload.orderId;
      }
      return null;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", this.modalData.name);
    },
    async confirmCancel() {
      this.$store.commit("ui/setLoader", true);
      if (this.orderId) {
        try {
          const cancelStatus = await this.$store.dispatch(
            "order/cancelOrder",
            this.orderId
          );
          if (cancelStatus.requestCreated) {
            let successNotfication = notifications.createNotification({
              type: "warning",
              message: `Cancellation request received.`,
            });
            this.$store.dispatch(
              "notification/spawnNotification",
              successNotfication
            );
          }
        } catch (error) {
          console.error(error);
          this.$store.dispatch(
            "notification/spawnNotification",
            notifications.createNotification({
              type: "danger",
              message:
                "Could not proceed with cancellation. Please try again or contact store directly.",
            })
          );
        }
      }
      this.$store.commit("ui/setLoader", false);
      this.$emit("close", this.modalData.name);
    },
  },
};
</script>

<style lang="scss" scoped>
$base-blue: #3276e3;
$medium-blue: darken($base-blue, 15%);
$dark-blue: darken($base-blue, 20%);
$light-blue: lighten($base-blue, 20%);
$soft-blue: lighten($base-blue, 10%);

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.card {
  text-align: center;
  border-radius: 8px;
  margin: 30px 0;
}

.title {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}

.sub-title {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}

.content {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;
}

.actions a {
  box-sizing: border-box;
  padding: 0.66rem 1.33rem;
  margin: 0px 8px;
  line-height: 16px;
  background: $medium-blue;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: white;
  text-decoration: none;
  border-radius: 8px;
}

.actions .nah {
  color: $dark-blue;
  background: $light-blue;
}

.actions .nah:hover {
  background: $soft-blue;
}

.actions a:hover {
  background: $dark-blue;
}
</style>
